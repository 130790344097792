<template>
  <div>
    <div>
      <img
        src="@/assets/images/prev_white.png"
        alt=""
        class="back-icon"
        @click="toBack"
      />
      <img src="@/assets/images/header.png" alt="" />

      <div class="help_center_content">
        <div class="help_center_item" @click="openKefuPopup">
          <div class="help_center_left">
            <img
              src="@/assets/images/wechat.png"
              alt=""
              class="help_center_icon"
            />
            <div>
              <p class="fz16">微信客服</p>
              <p class="help_center_tips">随时随地为您提供帮助</p>
            </div>
          </div>

          <img
            src="@/assets/images/8537.png"
            alt=""
            class="help_center_right"
          />
        </div>

        <a class="help_center_item" :href="kefuUrl">
          <div class="help_center_left">
            <img
              src="@/assets/images/714.png"
              alt=""
              class="help_center_icon"
            />
            <div>
              <p class="fz16">在线客服</p>
              <p class="help_center_tips">更多热门问题，实时为您解答</p>
            </div>
          </div>

          <img
            src="@/assets/images/8537.png"
            alt=""
            class="help_center_right"
          />
        </a>

        <a class="help_center_item" @click="toHelp">
          <div class="help_center_left">
            <img
              src="@/assets/images/793.png"
              alt=""
              class="help_center_icon"
            />
            <div>
              <p class="fz16">问题反馈</p>
              <p class="help_center_tips">有疑问或建议请及时联系我们</p>
            </div>
          </div>

          <img
            src="@/assets/images/8537.png"
            alt=""
            class="help_center_right"
          />
        </a>

        <div class="help_center_item" @click="copy">
          <div class="help_center_left">
            <img
              src="@/assets/images/792.png"
              alt=""
              class="help_center_icon"
            />
            <div>
              <p class="fz16">电子邮箱</p>
              <p class="help_center_tips">
                {{ email }}
                <span class="copy_text">复制</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="page_footer">
        <img src="@/assets/images/footer_2903.png" alt="" />
      </div>

      <div class="mask flex-center" v-if="isShowPopup">
        <img
          src="@/assets/images/798.png"
          alt=""
          class="mask_image"
          style="margin-top: -100px"
        />

        <div class="popup-bottom">
          <img
            src="@/assets/images/799.png"
            alt=""
            class="popup-bottom-icon"
            @click="downloadKefu"
          />
          <p class="fz12">保存图片</p>
          <div class="popup-bottom-cancel" @click="isShowPopup = false">
            取消
          </div>
        </div>
      </div>

      <img
        src="@/assets/images/kefu_save.png"
        id="kefu"
        alt=""
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShow: false,
      isShowPopup: false,
      email: 'gnsl.service@suzi.com.cn',
      baseUrl: process.env.VUE_APP_APP_URL,
      kefuUrl: '',
      envLoading: true,
      isUniApp: false
    }
  },
  created () {
    // ssss
    let avatar = this.$route.query.avatar
    let user_code = this.$route.query.user_code
    let org_name = this.$route.query.org_name
    this.kefuUrl = `${this.baseUrl}/customer_servive?avatar=${avatar}&user_code=${user_code}&org_name=${org_name}`
    this.isUniApp = !!this.$route.query.auth_token
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
  },
  methods: {
    toHelp () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateTo({
            url: '/pages/my/helpCenter/feedback'
          })
        }
      } else {
        window.location.href = this.baseUrl + '/sys_user/suggest'
      }
    },
    toBack () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    },
    copy () {
      this.$copyText(this.email)
      this.$toast('复制成功')
    },
    openKefuPopup () {
      this.isShowPopup = true
    },
    downloadKefu () {
      this.$downloadImg(window.location.origin + '/images/kefu_save.png')
      this.isShowPopup = false
      this.$toast('保存图片成功')
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
}
.avtive_footer {
  height: 24vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avtive_footer img {
  width: 31.2vw;
  height: 10.1333vw;
}
.mask {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
}
.mask .box {
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: 4vw;
  border-top-right-radius: 4vw;
}
.mask .box .top {
  display: flex;
  justify-content: space-between;
  padding: 4vw;
}
.mask .box .top span {
  font-size: 5vw;
  font-weight: 400;
  color: #17191c;
}
.mask .box .bottom {
  display: flex;
  padding: 10vw 0vw;
}
.mask .box .bottom .item {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #17191c;
  font-size: 5vw;
}
#app {
  background: #fff;
}
.help_center_content {
  width: 100%;
  background: #fff;
  border-radius: 5.3333vw 5.3333vw 0 0;
  margin-top: -4.2667vw;
  padding: 5.3333vw 4.2667vw;
  position: relative;
  z-index: 9;
  box-sizing: border-box;
}
.help_center_content .help_center_item {
  box-shadow: 0px 2px 20px 0px rgba(47, 88, 90, 0.1);
  height: 18.6667vw;
  border-radius: 1.3333vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.2667vw;
  margin-bottom: 3.2vw;
  box-sizing: border-box;
}
.help_center_content .help_center_item .help_center_left {
  display: flex;
  align-items: center;
  line-height: 1;
}
.help_center_content .help_center_item .help_center_icon {
  height: 7.4667vw;
  width: 7.4667vw;
  margin-right: 4.2667vw;
}
.help_center_content .help_center_item .help_center_right {
  height: 4.8vw;
  width: 4.8vw;
}
.help_center_content .help_center_item .help_center_tips {
  font-size: 3.2vw;
  color: #808388;
  margin-top: 2.1333vw;
}
.help_center_content .help_center_item .copy_text {
  color: #00a0ac;
  margin-left: 2.4vw;
  font-size: 3.2vw;
}
.page_footer {
  width: 100%;
  height: 24vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}
.page_footer img {
  width: 31.2vw;
  height: 10.1333vw;
}
.back-icon {
  position: fixed;
  top: 3.7333vw;
  left: 3.7333vw;
  height: 5.3333vw;
  width: 5.3333vw;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mask_image {
  width: 80vw;
  height: 80vw;
}
.popup-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #ebebeb;
  border-radius: 5.3333vw 5.3333vw 0 0;
  padding: 5.3333vw 4.2667vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3.2vw;
  color: #808388;
  box-sizing: border-box;
}
.popup-bottom .popup-bottom-icon {
  height: 13.3333vw;
  width: 13.3333vw;
  margin-bottom: 0.8vw;
}
.popup-bottom .popup-bottom-cancel {
  background-color: #fff;
  margin-top: 4vw;
  border-radius: 1.0667vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.2667vw;
  color: #202122;
  width: 91.2vw;
  height: 10.6667vw;
}
</style>
